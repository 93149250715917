<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <v-card :disabled="loading" :loading="loading">
      <v-card-title>
        Vardiya Onaylama
        <v-spacer />
        <v-btn depressed icon @click="onClickCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3" v-if="workplanRequest">
        <v-flex xs12 mt-2>
          <v-card elevation="0" class="grey lighten-4">
            <v-card-text class="py-0">
              <employee-profile :employee="workplanRequest.employee" />
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-text-field v-model.trim="dto.note" name="note" :label="$t('global.note')" />
        </v-flex>
        <v-flex xs12>
          <v-radio-group row mandatory v-model="dto.approved">
            <v-radio color="primary" :value="false" :label="$t('leave.request.denied')" />
            <v-radio color="primary" :value="true" :label="$t('leave.request.approved')" />
          </v-radio-group>
        </v-flex>
      </v-card-text>
      <v-card-actions class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn depressed @click.stop="onClickSave">{{ $t("buttons.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "confirm-dialog",
    props: {
      value: Boolean,
      data: Object
    },
    data: () => ({
      dialog: false,
      loading: false,
      workplanRequest: null,
      dto: {
        approved: false,
        note: null
      }
    }),
    watch: {
      value: {
        handler(status) {
          this.dialog = status;
        },
        immediate: true
      },
      data: {
        handler(item) {
          this.workplanRequest = item && item.workplanRequest;
        },
        deep: true
      }
    },
    methods: {
      onClickCancel() {
        this.dto.approved = null;
        this.dto.note = null;
        this.workplanRequest = null;
        this.$emit("close");
      },
      onClickSave() {
        this.loading = true;
        this.$api.workplanRequestService
          .approve(this.data.id, this.dto)
          .then(data => {
            if (data) {
              this.$emit("success");
              this.dto.note = null;
              this.dto.note = null;
              this.workplanRequest = null;
            }
          })
          .catch(console.error)
          .then(() => (this.loading = false));
      }
    }
  };
</script>

<style scoped></style>
